import {Grid} from "@mui/material";
import * as React from 'react';
export const Footer = ({html}) => {

    return (
        <React.Fragment>
            <Grid container  sx={{  backgroundColor: '#FFFFFF', fontSize: '7pt'}}>
               <Grid item xs={12} sx={{pt: '15px', pb: '5px', color: '#3C3D3F', fontWeight: 400}} dangerouslySetInnerHTML={{__html: html}}/>
            </Grid>
        </React.Fragment>
    )
}
