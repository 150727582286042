import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import PropTypes from "prop-types";
import {TranslatedText, translatedTexts} from "./lang";
import {Link} from "@mui/material";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PrivacyPolicy = ({country, handleFieldChange}) => {
    const [open, setOpen] = React.useState(false);
    const textTranslated: translatedTexts = TranslatedText(country);

    const handleClickOpen = () => {
        handleFieldChange();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Link href="#" onClick={handleClickOpen}>
                {textTranslated.privacy_policy_url_text}
            </Link>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{position: 'relative'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" sx={{marginLeft: 2,flex: 1}}>
                            {textTranslated.privacy_policy_url_text.toUpperCase()}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            {textTranslated.label_close}
                        </Button>
                    </Toolbar>
                </AppBar>
                <iframe
                    style={{transition: 'opacity .5s ease',
                        opacity: '1',
                        display: 'block',
                        margin: 'auto',
                        width: '100%',
                        height: '100%'}}
                    title={textTranslated.privacy_policy_url_text} id='iframus'  src={textTranslated.privacy_policy_url}></iframe>
            </Dialog>
        </div>
    );
}
PrivacyPolicy.propTypes = {
    country: PropTypes.string.isRequired,
    handleFieldChange: PropTypes.func.isRequired
}
