import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import '../../styling.css';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {
    Backdrop,
    CircularProgress,
    Paper,

} from "@mui/material";
import {useParams, useLocation} from "react-router-dom";
import {useEffect} from "react";

import {sendErrorMessage} from "../Error";
import logo from "../../assets/logo.png";
import {TranslatedText} from "./lang";

import {Header} from "../Header";
import {Details} from "../Details";
import {Form} from "./Form";
import {Footer} from "../Footer";
import {Confirmation} from "../Confirmation";
import {WebFormNotOpen} from "./WebFormNotOpen";
const theme = createTheme();

enum Consent {
    RequestNotSent= "Request not sent",
    Approved = "Approved",
    Pending = "Pending",
    NotApproved = "Not approved",
    PartialApproval = "Partial approval",
    OptOut = "Opt out"
}
const emptyObj = {
    DateTime:'',
    questions: [],
    fields: [],
    legalText: '',
    LegalText: '',
    firstNameLabel:'',
    lastNameLabel:'',
    siteLabel: '',
    emailLabel:'',
    otherInformationLabel:'',
    submitLabel: '',
    Submit: '',
    participate:'',
    __approvalCode: '',
    __LogoImage: '',
    __FooterImage: '',
    ConfirmationText: 'Du kommer inom kort att få en e-post som bekräftelse på din anmälan, du kan nu stänga detta fönster!',
    ConfirmationThankYouText: 'Tack för din anmälan',
    Footer: ''

}
interface SignUpInput {
    isEdit?: boolean,
    isApproval?: boolean
}
export const SignUp = ({isEdit,isApproval}: SignUpInput) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const params = useParams();
    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    // @ts-ignore
    const uuid = React.useRef(params.uuid)
    const [content, setContent] = React.useState<TypeContent>(emptyObj);
    const [signUp, setSignUp] = React.useState<TypeSignUp>({start: new Date, end: new Date,consent: 'Request not sent', country: '', firstname: '', lastname: '', middlename: '', phone: '', address: '', email: '', site: '',participate: '', otherinformation: '', isOpen: true, questions: [], fields: {}, question: ''});
    const [isPreview, setIsPreview] = React.useState(false);
    const [isEditor, setIsEditor] = React.useState(false);
    const [linkSent, setLinkSent] = React.useState(false);
    const [translatedText, setTranslatedText] = React.useState(TranslatedText());
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [showConsent, setShowConsent] = React.useState(false);
    const [fieldError, setFieldError] = React.useState<any>({});
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [displayToolbar, setDisplayToolbar] = React.useState('');
    const [colorPreset, setColorPreset] = React.useState(undefined);
    const [smallLogoImages, setSmallLogoImages] = React.useState(undefined);
    const [jointEvent, setJointEvent] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState('SE');
    const [footer, setFooter] = React.useState('');

    const getParticipant = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SIGNUP_API}${uuid.current}`);
            let tmpContent: TypeContent = content;
            for (let field of res.data.template.fields) {
                if ( field.name === "__Questions" ) {
                    field.name = field.name === "__Questions" ? "questions" : field.name;
                    field.value = field.questions;
                }
                tmpContent = {...tmpContent, [field.name]: field.value};
            }
            const tmpCountry = searchParams.get('country') ?? res.data.participant.country;
            tmpContent.fields = res.data.template.fields;
            setCountry(tmpCountry);
            setColorPreset(res.data?.colorPreset);
            setJointEvent(res.data?.jointEvent);
            setSmallLogoImages(res.data?.smallLogoImages);
            setFooter(res.data?.Footer);
            setSignUp({...signUp,
                email: res.data.participant.email,
                country: tmpCountry,
                fields: {
                    email: res.data.participant.email,
                    site: res.data.participant.site,
                    country: tmpCountry,
                },
                consent: res.data.participant.consent,
                isOpen: !isApproval ? res.data.isOpen : isApproval,
                start: res.data.start,
                end: res.data.end,
                limitation: {
                    message: res.data.template.limitation.message,
                    max: res.data.template.limitation.max,
                    status: res.data.template.limitation.status,
                    numRegistered: res.data.numRegistered,
                    isFull: res.data.template.limitation.status !== 'no-limitation' && res.data.numRegistered.total >= res.data.template.limitation.max,
                    details: res.data.template.limitation.details,
                }
            });
            console.log({res})
            setTranslatedText(TranslatedText(tmpCountry, 'Tester'));
            setShowConsent(((tmpCountry === 'SE' && !searchParams.get('consent')) || searchParams.get('consent') === 'true') &&
                (res.data.participant.consent === Consent.RequestNotSent || res.data.participant.consent === Consent.Pending))
            setLoading(false);
            setContent(tmpContent);
        } catch ( e: any ) {
            setLoading(false);
            setLinkSent(true);
            setErrorMessage(true);
            await sendErrorMessage(e,  'public/signup/getParticipant', "Cannot Get Participant");
        }
    }
    const getEditParticipant = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_SIGNUP_API}edit/${uuid.current}`);
            let tmpContent: TypeContent = content;
            for (let field of res.data.template.fields) {
                if ( field.name === "__Questions" ) {
                    field.name = field.name === "__Questions" ? "questions" : field.name;
                    field.value = field.questions;
                }
                tmpContent = {...tmpContent, [field.name]: field.value};
            }
            tmpContent.fields = res.data.template.fields;
            setCountry(searchParams.get('country') ?? res.data.participant.country??'');
            setColorPreset(res.data?.colorPreset);
            setJointEvent(res.data?.jointEvent);
            setSmallLogoImages(res.data?.smallLogoImages);
            setFooter(res.data?.Footer)
            const fields = {};
            const customFields = res.data.participant?.customFields;
            Object.keys(res.data.participant).map( key  => {
                const fieldName = key.toLowerCase() === 'name' ? 'lastname' : key.toLowerCase()
                fields[fieldName] = res.data.participant[key]
            })
            setSignUp({...signUp,
                country,
                fields: {
                    ...fields,
                    ...customFields,
                    country
                },
                isOpen: !isApproval ? res.data.isOpen : isApproval,
                start: res.data.start,
                end: res.data.end
            });
            setTranslatedText(TranslatedText(country, 'Tester'));
            setShowConsent(((country === 'SE' && !searchParams.get('consent')) || searchParams.get('consent') === 'true') &&
                (res.data.participant.consent === Consent.RequestNotSent || res.data.participant.consent === Consent.Pending))
            setLoading(false);
            setContent(tmpContent);
        } catch ( e: any ) {
            setLoading(false);
            setLinkSent(true);
            setErrorMessage(true);
            await sendErrorMessage(e,  'public/signup/getParticipant', "Cannot Get Participant");
        }
    }
    const getField = (fieldName: string) => {
        return content.fields.find( f => f.name.toLowerCase() === fieldName.toLowerCase());
    }
    useEffect( () => {
        if ( uuid.current !== "PREVIEW" ) {
            (async() => {
                if (isEdit)
                    await getEditParticipant()
                else
                    await getParticipant();
            })();
        } else {
            window.addEventListener("message", (event) => {
                let tmpContent: TypeContent = content;
                if ( event.data.type === "updatePreview" ) {
                    setSmallLogoImages(event.data?.smallLogoImages);
                    setColorPreset(event.data?.colorPreset)
                    setJointEvent(event.data?.jointEvent);
                    setIsPreview(true);
                    setIsEditor(event.data.isEditor)
                    setFooter(event.data?.Footer)
                    for (let field of event.data.fields) {
                        if ( field.name === "__Questions" ) {
                            field.name = field.name === "__Questions" ? "questions" : field.name;
                            field.value = field.questions;
                        }
                        tmpContent = {...tmpContent, [field.name]: field.value};
                    }
                    tmpContent.fields = event.data.fields;
                    const country = event.data.country;
                    setSignUp({...signUp,
                        country,
                    });
                    setTranslatedText(TranslatedText(country, 'Tester'));
                    setContent(tmpContent);
                    setLoading(false);
                }

            }, false);
        }



    }, [])
    const updateField = (fieldName: string) => {
        if (isPreview && isEditor) {
            const fieldType = content.fields.find( f => f.name === fieldName)?.type;
            let type;
            switch (fieldType) {
                case 'Image':
                    type = 'updateTemplateImage'
                break;
                case 'Text':
                    type = 'updateTemplateField'
                break;
                default:
                    type = 'updateTemplateInputField'
                break;
            }

                window.parent.postMessage({type, fieldName, fieldType }, "*");
        }
    }
    const addField = () => {
        if (isPreview && isEditor) {
            window.parent.postMessage({type: 'addTemplateInputField' }, "*");
        }
    }
    const deleteField = (name: string) => {
        if (isPreview && isEditor) {
            if (name)
                window.parent.postMessage({type: 'deleteTemplateInputField', name }, "*");
        }
    }
    const moveFieldUp = (name: string) => {
        if (isPreview && isEditor) {
            if (name)
                window.parent.postMessage({type: 'moveFieldUp', name }, "*");
        }
    }
    const moveFieldDown = (name: string) => {
        if (isPreview && isEditor) {
            if (name)
                window.parent.postMessage({type: 'moveFieldDown', name }, "*");
        }
    }

    const handleSaveQuestions = () => {
        // @ts-ignore
        const data = new FormData(document.getElementById('form'));
        const result: TypeSignUpQuestions[] = [];
        let parentValue = false;
        // @ts-ignore
        for(const pair of data.entries()) {
            const _id = pair[0].split("_")[1];
            if (_id) {
                const innerRes: TypeSignUpQuestions = {_id};
                const type = pair[0].split("_")[0];
                let value = pair[1];
                switch (type) {
                    case 'yesNo':
                        value = value === 'true';
                    break;
                    case 'checkbox':
                        value = value === 'on';
                    break;
                }
                if (!result.hasOwnProperty(_id)) {
                    const question = content.fields.find( (q: TemplateFieldType) => q._id === _id);
                    value = question?.parentField && !parentValue ? false : value;
                    // @ts-ignore
                    innerRes.label = question?.label;
                }
                result.push({...innerRes, type, value })
                parentValue = value;
            }

        }
        return result;
    }
    const handleSavedFields = () => {
        // @ts-ignore
        const data = new FormData(document.getElementById('form'));
        const fields = {};
        // @ts-ignore
        for(const pair of data.entries()) {
            let [name, value] = pair;
            const type = content.fields.find( f => f.name?.toLowerCase() ===  name)?.type;
            switch ( type ) {
                case 'Checkbox':
                    value = value === 'on';
                    break;
                case 'YesNo':
                    value = value === 'true';
                    break;
            }
            // @ts-ignore
            fields[name] = value;
        }
        return fields;
    }
    const handleCheckBoxChange = (event: React.ChangeEvent<{ value: string; checked?: boolean }>) => {
        SaveFormValues();
        setDisableSubmit(false);
    };
    const SaveFormValues = (event?: any) => {

        //getCustomFields();
        // @ts-ignore
        const data = new FormData(document.getElementById('form'));
        const fields = handleSavedFields();
        // @ts-ignore,
        setSignUp({...signUp, fields, firstname: data.get("firstname"), middlename: data.get("middlename"), lastname: data.get("lastname"), email: data.get("email"), phone: data.get('phone'), address: data.get('address'), site: data.get("site"), otherinformation: data.get("otherinformation"), consent: showConsent ?  data.get("consent") : signUp.consent});
        setFieldError({});
        return {...signUp, fields, firstname: data.get("firstname"), middlename: data.get("middlename"), lastname: data.get("lastname"), email: data.get("email"), phone: data.get('phone'), address: data.get('address'), site: data.get("site"), otherinformation: data.get("otherinformation"), consent: showConsent ?  data.get("consent") : signUp.consent};

        return signUp;
    };
    const validateFields = (fieldNames: string[], data: any )=> {
        for( const fieldName of fieldNames) {
            if (!validateField(fieldName, data) ) {
                return false;
            }
        }
        return true;
    }
    const validateField = (fieldName: string, data: any )=> {
        const parentField = getField(fieldName)?.parentField??undefined;
        const isRequired = getField(fieldName)?.isRequired??false;
        const isHidden = getField(fieldName)?.hidden??false;
        if ( isRequired && parentField && !isHidden ) {
            const parentFieldName = content.fields.find( f => f._id === parentField)?.name?.toLowerCase();
            if (parentFieldName) {
                const parentValue = signUp.fields[parentFieldName];
                if (!parentValue) {
                    //Parent fields has no value, therefore this field is not visible, therefore it is ok
                    return true;
                }
            }
        }
        return (isRequired && !!data.get(fieldName)) || !isRequired ||isHidden
    }
    const getCustomFields = (localSignup: TypeSignUp = signUp) => {
        const customFields = {};
        for ( const field in localSignup.fields ) {
            const contentField = content.fields.find( f => f.name.toLowerCase() === field.toLowerCase());
            if (contentField && !contentField.defaultField)
                customFields[field.toLowerCase()] = localSignup.fields[field];
        }
        return customFields;
    }
    // @ts-ignore
    const handleSubmit = async (event) => {
        try {
            const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            const localSignup: any = SaveFormValues();
            const questions = handleSaveQuestions();
            const missingQuestions = {};
            for ( const question of content.questions ) {
                if ((question._id && (questions.find((q: TypeSignUpQuestions) => q._id === question._id))) || (!question.useRating && !question.useYesNo) || (question.parentQuestion && !signUp.questions.find( (q: TypeSignUpQuestions) => q._id === question.parentQuestion)?.value)) {
                    console.log(question.label, 'is filled out');
                } else {
                    //missedQuestions +=`\n${question.category} - ${question.label}`;
                    console.log(question.label, 'is not filled out');
                    if (question._id)
                        missingQuestions[question._id] = true;
                }
            }
            if (!isPreview && !isApproval) {
                if (
                    !validateFields(
                        [
                            'firstname', 'lastname', 'email', 'site', 'middlename',
                            'address', 'phone', 'otherinformation'],
                        data) ||
                    !re.test(String(data.get("email")).toLowerCase()) || Object.keys(missingQuestions).length > 0) {
                    setFieldError( {
                        firstname: !validateField('firstname', data),
                        lastname: !validateField('lastname', data),
                        middlename: !validateField('middlename', data),
                        phone: !validateField('phone', data),
                        address: !validateField('address', data),
                        site: !validateField('site', data),
                        otherinformation: !validateField('otherinformation', data),
                        email: !validateField('email', data) || !re.test(String(data.get("email")).toLowerCase()),
                        ...missingQuestions
                    });
                    return false
                }
                setLoading(true);
                const reCaptcha = await handleReCaptchaVerify();
                await axios.put(`${process.env.REACT_APP_SIGNUP_API}`, {
                    payload: {
                        _id: uuid.current,
                        firstname: data.get("firstname"),
                        name: data.get("lastname"),
                        middlename: data.get("middlename"),
                        email: data.get("email"),
                        phone: data.get("phone"),
                        address: data.get('address'),
                        site: data.get("site"),
                        country: signUp.country,
                        participate: true,
                        customFields: getCustomFields(localSignup),
                        questions,
                        otherInformation: data.get("otherinformation"),
                        consent: data.get('consent') === 'true' ? 'Approved' : signUp.consent
                    },
                    action: 'SUBMIT_REGISTRATION',
                    reCaptcha
                });


                setLoading(false);
            }
            setLinkSent(true);


        } catch ( e: any ) {
            setLoading(false);
            setLinkSent(true);
            setErrorMessage(true);
            await sendErrorMessage(e,  'public/signup/handleSubmit', "Could not submit");
        }
    };
    const handleReCaptchaVerify = async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }
        const token = await executeRecaptcha('signup');
        return token;
        // Do whatever you want with the token
    };

    return (
        <ThemeProvider theme={theme}>

                    <Container component="main" maxWidth="md" sx={{marginTop: 'auto', marginBottom: 'auto', width: '100%', maxWidth: '700px!important'}}>
                        <Paper elevation={20} >
                            <CssBaseline />
                            <Box
                                className="PaperBox"
                                sx={{
                                    marginTop: 8,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%'
                                }}
                            >

                                <Header
                                    Header={content.Header}
                                    SubHeader={content.SubHeader}
                                    DateTime={content.DateTime}
                                    TypeOfEvent={content.TypeOfEvent}
                                    logoImage={content.__LogoImage}
                                    smallLogo={content.__FooterImage}
                                    isEditor={isEditor}
                                    isPreview={isPreview}
                                    colorPreset={colorPreset}
                                    smallLogoImages={smallLogoImages}
                                    updateField={updateField}
                                    jointEvent={jointEvent}
                                />

                                { signUp.isOpen && signUp?.limitation?.isFull && <WebFormNotOpen country={country} end={signUp.end} start={signUp.start} type={'REGISTRATION'} message={signUp?.limitation?.message} /> }
                                { !signUp.isOpen && <WebFormNotOpen country={country} end={signUp.end} start={signUp.start} type={'REGISTRATION'}  /> }
                                        <>
                                            {
                                                errorMessage ? <img style={{marginTop: '20px'}} alt="Tillotts Logo"
                                                                    src={logo}/> : ''}
                                            {
                                                errorMessage &&
                                                <Typography
                                                    style={{cursor: isPreview && isEditor ? 'pointer' : 'unset'}}
                                                    onClick={() => updateField('ConfirmationThankYouText')}
                                                    component="div" variant="h4" sx={{marginTop: '20px'}}
                                                    dangerouslySetInnerHTML={{__html: linkSent && !errorMessage ? `${content.ConfirmationThankYouText}` : errorMessage ? `Uh uh, we are really sorry!` : ""}}
                                                />
                                            }

                                            {
                                                linkSent && !errorMessage &&
                                                <Box onClick={() => updateField('ConfirmationText')} sx={{
                                                    cursor: isPreview && isEditor ? 'pointer' : 'unset',
                                                    width: '100%'
                                                }}> <Confirmation content={content.ConfirmationText}/></Box>
                                            }
                                            {errorMessage ? <Typography component="div" variant="h5" sx={{
                                                marginTop: '10px',
                                                paddingRight: '20px',
                                                paddingLeft: '40px',
                                                width: '100%',
                                                textAlign: 'center'
                                            }}>
                                                An unexpected error has occurred and we could sadly not handle your
                                                request. <br/>As an alternative, please register by sending an e-mail
                                                to <a
                                                href="mailto: rose-marie.blom@tillotts.com">rose-marie.blom@tillotts.com</a>
                                            </Typography> : ''}

                                            {
                                                !linkSent &&
                                                <Details
                                                    content={content.GeneralInformation ?? content.generalInformation ?? ''}
                                                    isEditor={isEditor}
                                                    isPreview={isPreview}
                                                    updateField={() => updateField('GeneralInformation')}
                                                />
                                            }

                                            {
                                                !linkSent &&
                                                <>
                                                    <Form
                                                        colorPreset={colorPreset}
                                                        content={content}
                                                      isEdit={!!isEdit}
                                                      getField={getField}
                                                      showConsent={showConsent}
                                                      translatedText={translatedText}
                                                      disableSubmit={disableSubmit}
                                                      handleSubmit={handleSubmit}
                                                      handleCheckBoxChange={handleCheckBoxChange}
                                                      isPreview={isPreview}
                                                      displayToolbar={displayToolbar}
                                                      setDisplayToolbar={setDisplayToolbar}
                                                      fieldError={fieldError}
                                                      SaveFormValues={SaveFormValues}
                                                      signUp={signUp}
                                                      addField={addField}
                                                      isEditor={isEditor}
                                                      updateField={updateField}
                                                      moveFieldUp={moveFieldUp}
                                                      moveFieldDown={moveFieldDown}
                                                      deleteField={deleteField}
                                                        setSignUp={setSignUp}
                                                    />

                                                    <Footer
                                                        smallLogo={content.__FooterImage}
                                                        AboutTillotts={content.AboutTillotts}
                                                        Contact={content.Contact}
                                                        smallLogoImages={smallLogoImages}
                                                        jointEvent={jointEvent}
                                                        html={footer}
                                                    />
                                                </>

                                            }

                                            <span style={{
                                                color: 'gray',
                                                fontSize: '9pt',
                                                marginBottom: '20px',
                                                marginTop: '20px'
                                            }}>{content.__approvalCode}</span>
                                        </>

                                    </Box>
                        </Paper>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={loading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Container>
        </ThemeProvider>
    );
}
