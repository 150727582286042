import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {MeetingType} from "./MeetingType";
import {Agenda} from "./Agenda";
import {Speakers} from "./Speakers";
import {QR} from "./QR";

export const Body = ({content, colorPreset, uuid, updateField, isEditor, isPreview, displayAgenda}: {content: TypePrintContent, colorPreset: any, uuid: string, updateField: any, isEditor: boolean, isPreview: boolean, displayAgenda: boolean}) => {

    return (
        <Grid container paddingTop={'20px'} height={'200mm'} minHeight={'200mm'}>
            <Grid item xs={displayAgenda ? 6 : 12}>
                <MeetingType colorPreset={colorPreset} TypeOfEvent={content['TypeOfEvent']} DateTime={content['DateTime']} updateField={updateField} isEditor={isEditor} isPreview={isPreview} />
                <Box onClick={() => updateField('Welcome Text')} sx={{cursor: isPreview && isEditor ? 'pointer': 'unset',pt: '20px'}} dangerouslySetInnerHTML={{__html: content['Welcome Text']}}/>
                <Speakers content={content}/>
            </Grid>
            {
                displayAgenda &&
                <Grid item xs={6} >
                    <Agenda colorPreset={colorPreset} content={content} updateField={updateField} isEditor={isEditor} isPreview={isPreview}/>
                </Grid>
            }

            <Grid item xs={12} >
                <QR uuid={uuid} content={content} updateField={updateField} isEditor={isEditor} isPreview={isPreview}/>
            </Grid>
        </Grid>

)
}