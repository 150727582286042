import Box from "@mui/material/Box";
import QRCode from 'react-qr-code';
export const QR = ({uuid, content, updateField, isPreview, isEditor}) => {
    const url = `${window.location.origin}/register/${uuid}`;
    return (
        <Box textAlign={'right'} paddingTop={2}>
            <svg width="230px" viewBox="0 0 466 144" xmlns="http://www.w3.org/2000/svg" style={{}}>
                <path fill="#D51317" d="M0,40 L361,40 L361,0 L466,72 L361,144 L361,104 L0,104 Z" />
                <text x="40%" y="46%" dominantBaseline="middle" textAnchor="middle" fontSize="23" fontWeight="700" fill="white" dy=".3em" onClick={() => updateField('Button')} style={{cursor: isPreview && isEditor ? 'pointer': 'unset'}}>{content.Button}</text>
            </svg>
            <QRCode style={{marginLeft: 10}} size={80} value={url} />
        </Box>
    )
}