import {Box, Grid, Typography} from "@mui/material";
import * as React from 'react';
export const Confirmation = ({content}: {content: string}) => {

    return (
        <React.Fragment>
            <Grid className="ConfirmationWrapper" container  sx={{pl: '40px', pr: '40px', pt: '20px', pb: '20px', width: '100%',  backgroundColor: 'rgba(60, 61, 63, 0.08)'}}>
                <Grid className="Confirmation" item xs={12} sx={{pt: '15px', pb: '15px', color: '#3C3D3F', fontSize: '18px', fontWeight: 700}} dangerouslySetInnerHTML={{__html: content}} />
            </Grid>
        </React.Fragment>
    )
}
